import React from "react"
import "twin.macro"

// Components
import Anchor from "@components/Anchor"

const QuizThankYou = () => {
  return (
    <div tw="text-center">
      <h2 tw="font-cocon text-studio font-bold text-5xl leading-[1.25]">
        Thank you, your email is on the way.
      </h2>
      <p tw="text-sm font-medium pt-2.5 lg:(text-xl pt-6)">
        Don&#39;t stop here. If you&#39;re interested in learning more about
        XYWAV download this{" "}
        <Anchor link="/pdf/XYWAV_IH_Brochure.pdf" newTab>
          Patient Brochure
        </Anchor>
        .
      </p>
      <p tw="text-sm font-medium py-2.5 lg:(text-xl py-6)">
        Just below is a video series featuring Diana, a real patient and XYWAV
        IH Ambassador, who has a conversation with her sleep specialist on
        Idiopathic Hypersomnia symptoms and&nbsp;XYWAV.
      </p>
      <Anchor link="#patient-video">
        <p>Watch Diana&#39;s Videos</p>
      </Anchor>
    </div>
  )
}

export default QuizThankYou
