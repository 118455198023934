import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import Container from "@components/container"
import { Heading2 } from "@styles/ui"
import Callout from "@components/callout"
import LearnMore from "@components/learnMore"
import DownloadItem from "@components/DownloadItem"
import Section from "@components/section"
import VideoGallery from "@components/videoGallery"
import Quiz from "@components/quiz/Quiz"

const TalkingToYourDoctor = ({ location, data }) => {
  const RECAPTCHA_3_KEY = process.env.GATSBY_RECAPTCHA_3_KEY

  const learnMoreData = [
    {
      url: "/idiopathic-hypersomnia/getting-xywav/",
      text: "Learn more about getting XYWAV",
    },
    {
      url: "/idiopathic-hypersomnia/jazzcares-for-xywav/",
      text: "Find out what financial support is available",
    },
  ]
  const downloads = [
    {
      title: "Epworth Sleepiness Scale (ESS)",
      description:
        "The ESS can help you find your level of daytime sleepiness. Be sure to share your results with your doctor.",
      pdf: "/pdf/ESS_Downloadable_PDF.pdf",
    },
    {
      title: "Idiopathic Hypersomnia Severity Scale (IHSS)",
      description:
        "The IHSS can help you rate the severity and frequency of your Idiopathic Hypersomnia symptoms. Be sure to share your results with your doctor.",
      pdf: "/pdf/IHSS_Downloadable_PDF.pdf",
    },
  ]

  const videoGallery = [
    {
      wistiaId: "4q9xe20fta",
      title: "Diana & Dr. Meskill on identifying Idiopathic Hypersomnia",
      description:
        "Asking questions about how Diana feels day and night helps Dr. Meskill pinpoint the symptoms of Idiopathic Hypersomnia.",
      thumbnail: data.vidThumb1,
      thumbnailSmall: data.vidThumb1Small,
      alt: "Patient Diana and Dr. Meskill discuss symptoms of Idiopathic Hypersomnia",
      id: 0,
    },
    {
      wistiaId: "cvpdirh8og",
      title: "Diana & Dr. Meskill on sleep inertia",
      description: (
        <>
          Diana tells Dr. Meskill that she has a lot of trouble getting up in
          the morning&mdash;even with multiple alarms.
        </>
      ),
      thumbnail: data.vidThumb2,
      thumbnailSmall: data.vidThumb2Small,
      alt: "Patient Diana and Dr. Meskill discuss sleep inertia",
      id: 1,
    },
    {
      wistiaId: "37olgdk4j9",
      title: "Diana & Dr. Meskill on Idiopathic Hypersomnia symptoms",
      description:
        "Diana thinks beyond excessive daytime sleepiness to explore her additional IH symptoms.",
      thumbnail: data.vidThumb3,
      thumbnailSmall: data.vidThumb3Small,
      alt: "Patient Diana and Dr. Meskill discuss Excessive Daytime Sleepiness (EDS)",
      id: 2,
    },
    {
      wistiaId: "139dj201gv",
      title: "Diana & Dr. Meskill on treatment goals",
      description: (
        <>
          Dr. Meskill recommends XYWAV, the only FDA&#8209;approved option for
          adults with IH.
        </>
      ),
      thumbnail: data.vidThumb4,
      thumbnailSmall: data.vidThumb4Small,
      alt: "Patient Diana and Dr. Meskill discuss how XYWAV can help in her treatment journey",
      id: 3,
    },
    {
      wistiaId: "vt3h5j8bg7",
      title: "Diana & Dr. Meskill discuss XYWAV",
      description:
        "See how Dr. Meskill describes XYWAV and helps Diana get started on her treatment journey.",
      thumbnail: data.vidThumb5,
      thumbnailSmall: data.vidThumb5Small,
      alt: "Patient Diana and Dr. Meskill discuss the benefits of XYWAV",
      id: 4,
    },
  ]

  return (
    <Layout location={location}>
      <Seo
        title="Talking to Your Doctor About Idiopathic Hypersomnia | XYWAV® for IH"
        description="Complete the interactive sleep quiz to help your doctor better understand your symptoms and develop a treatment plan for your Idiopathic Hypersomnia (IH). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      {/* Static hero */}
      <StaticHero
        eyebrow="talking with your doctor"
        imageData={data.heroImage}
        alt="Man talking with his doctor about XYWAV®"
        jumpLinks={[
          { text: "Take quiz", link: "#take-quiz" },
          { text: "Watch patient videos", link: "#patient-video" },
        ]}
        gradient="purple"
      >
        Talk with your doctor and find out if XYWAV may be right for you
      </StaticHero>

      <Section id="take-quiz">
        <Container>
          <h2 css={[Heading2, tw`pb-2 xl:(pb-5)`]}>
            How do you experience Idiopathic Hypersomnia each&nbsp;day?
          </h2>
          <p tw="mt-4 font-black">
            Idiopathic Hypersomnia can affect you in many ways—from how much
            sleep you get, to how you feel when you&#39;re awake, and even when
            you&#39;re trying to wake&nbsp;up.
          </p>
          <p tw="mt-4">
            This quiz helps you create a list of the ways Idiopathic Hypersomnia
            could be impacting you—some of which you may not have even realized.
            It may also help you and your doctor decide if XYWAV could be right
            for you. Get started with the quiz below and be sure to bring the
            results with you to your next appointment.
          </p>
          <div tw="mt-8 md:scroll-mt-20" id="quiz">
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
              <Quiz />
            </GoogleReCaptchaProvider>
          </div>
        </Container>
      </Section>

      {/* Video gallery */}
      <Section bgColour={tw`bg-lilac`} id="patient-video">
        <Container>
          <h2 css={[Heading2, tw`text-studio mb-2.5`]}>
            Don&apos;t know where to start when talking about your treatment
            goals?
          </h2>
          <p tw="mb-7 lg:mb-12">
            Watch as Diana, a real patient and XYWAV IH Ambassador, and her
            sleep specialist, Dr. Gerard J. Meskill, guide you through their
            conversations about Idiopathic Hypersomnia symptoms and XYWAV.
          </p>
          <VideoGallery
            videos={videoGallery}
            location={location}
            sectionId="diana"
          />
        </Container>
      </Section>

      <Section bgColour={tw`bg-lilac`}>
        <Container>
          <h2
            css={[
              Heading2,
              tw`pb-8 px-2 text-4xl text-left xl:(text-[32px] px-0)`,
            ]}
          >
            Download these tools to check your symptoms then share your
            responses with your doctor.
          </h2>
          <DownloadItem downloads={downloads} />
        </Container>
      </Section>

      <Callout
        heading="Live & on-demand webinars"
        cta={{
          text: "go to webinars",
          external: true,
          url: "https://www.jazzwebinars.com/xywav-idiopathic-hypersomnia?tab=ondemand",
        }}
        noPadding={true}
      >
        <p>
          Check out our webinar series presented by experts in Idiopathic
          Hypersomnia and treatment with XYWAV. Programs cover a variety of
          topics and even include a live Q&A!
        </p>
      </Callout>
      <LearnMore ih ctas={learnMoreData} colour="purple" />
    </Layout>
  )
}

export default TalkingToYourDoctor

export const query = graphql`
  query {
    heroImage: file(
      relativePath: { eq: "talk-to-your-doctor-ih/man-talking-to-doctor.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800, formats: [AUTO, WEBP, AVIF])
      }
    }
    vidThumb1: file(
      relativePath: { eq: "video-thumbnails/patient-diana-ih-symptoms.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb1Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-ih-symptoms.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb2: file(
      relativePath: { eq: "video-thumbnails/patient-diana-sleep-inertia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb2Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-sleep-inertia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb3: file(
      relativePath: { eq: "video-thumbnails/patient-diana-eds.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb3Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-eds.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb4: file(
      relativePath: { eq: "video-thumbnails/patient-diana-treatment-goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb4Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-treatment-goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb5: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-benefits.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb5Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-benefits.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
  }
`
